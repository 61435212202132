import { GET_HOME_DATA } from "../actions/homeLibrary";

export default (state = {}, { type, payload = [] }) => {
    switch (type) {
        case GET_HOME_DATA:
            return payload;
        default:
            return state;
    }
};
