import { fetchHomeData, fetchLibrary } from "../services/blt";
import { setHttpResponseCode } from "./system";
import { selectLibrary } from "../selectors";
import { toReducerKey } from "../../util/reducer-helpers";

export const GET_HOME_DATA = "GET_HOME_DATA";
export const GET_LIBRARY_DATA = "GET_LIBRARY_DATA";

export const addHomeData = (homeData) => ({
    type: GET_HOME_DATA,
    payload: homeData,
});

/* Async Action Creators */
export const getHomeData = (params, headers) => (dispatch) =>
    fetchHomeData(params, headers)
        .then((homeData) => {
            dispatch(setHttpResponseCode(200));
            Promise.resolve(dispatch(addHomeData(homeData)));
        })
        .catch((err) => {
            dispatch(setHttpResponseCode(err.status));
            Promise.resolve(dispatch(addHomeData(err)));
        });

export const addLibraryData = (libraryData, lang) => ({
    type: GET_LIBRARY_DATA,
    payload: libraryData || {},
    lang,
});

/* Async Action Creators */
export const getLibraryData =
    (params, headers) => async (dispatch, getState) => {
        let state = getState();
        let { lang, uri } = params;

        let status = 200;
        let libraryKey = toReducerKey(uri, lang);
        let library = selectLibrary(state, libraryKey);

        // TODO: find out if we need to manually set title and uri with the below values, like we used to do in selectLibrary.
        // title: selectI18nStringById('defaultLibraryBreadCrumb'),
        // uri: `${APP_PATHNAME}/lib`,

        if (!library.loaded) {
            try {
                library = await fetchLibrary(params, headers);
            } catch (err) {
                status = err.status;
                library = err;
            }

            dispatch(setHttpResponseCode(status));
            dispatch(addLibraryData(library, params.lang));
        }

        return library;
    };
