import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import loadable from "@loadable/component";
import mem from "mem";

import { getBookmarks, getSets } from "../actions/annotations";
import { getHomeData, getLibraryData } from "../actions/homeLibrary";
import { clearTocCollectionOverrides } from "../actions/reader";
import { setActivePanel } from "../actions/sidePanel";
import { asyncCheckLogin } from "../actions/system";
import {
    selectActivePanel,
    selectAnnotationsOutOfService,
    selectLoggedIn,
    selectContent,
    selectI18nStringById,
    selectLang,
    selectLibrary,
    selectShowArchivedContent,
    selectSortedBookmarks,
} from "../selectors";
import analytics from "../../util/analytics";
import { buildCategoryTypes } from "../../util/type-utils";
import { prependAppBase } from "../../util/uri-utils";

import Loading from "../components/Loading/Loading";

const Home = loadable(
    () =>
        import(
            /* webpackChunkName: 'home' */ "../templates/HomeLibrary/HomeLibrary"
        ),
    { fallback: <Loading /> }
);

const mapStateToProps = (state, ownProps) => {
    let location = ownProps.location;
    let library = selectLibrary(state, location);

    return {
        activePanel: selectActivePanel(state),
        annotationsOutOfService: selectAnnotationsOutOfService(state),
        additionalItems: state.home.additionalItems,
        bookmarks: selectSortedBookmarks(state),
        content: selectContent(state, location),
        dir: state.system.dir,
        featureItems: state.home.featureItems,
        homeStatus: state.home.status,
        i18n: state.i18n,
        lang: selectLang(state),
        library,
        location,
        loggedIn: selectLoggedIn(state),
        meta: state.home.meta,
        selectI18nStringById: selectI18nStringById(state),
        showArchivedContent: selectShowArchivedContent(state),
        streamItems: state.home.streamItems,
        system: state.system,
    };
};

const mapLangToActions = mem((dispatchProps, lang) => ({
    getHomeData: (params, headers) =>
        dispatchProps.getHomeData({ lang, ...params }, headers),
    getLibraryData: (params, headers) =>
        dispatchProps.getLibraryData({ lang, ...params }, headers),
}));

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const lang = stateProps.lang;

    return {
        ...ownProps,
        ...stateProps,
        ...dispatchProps,
        ...mapLangToActions(dispatchProps, lang),
    };
};

const storeConnector = connect(
    mapStateToProps,
    {
        asyncCheckLogin,
        clearTocCollectionOverrides,
        getBookmarks,
        getHomeData,
        getLibraryData,
        getSets,
        setActivePanel,
    },
    mergeProps
);

const HomeLibraryContainer = (props) => {
    const {
        asyncCheckLogin,
        getHomeData,
        getLibraryData,
        lang,
        library,
        meta,
        annotationsOutOfService,
        crossLinkMode,
        getBookmarks,
        getSets,
        loggedIn,
    } = props;

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(clearTocCollectionOverrides());
        getSecureData();

        if (!library.loaded) {
            getLibraryData({ uri: prependAppBase("/"), lang });
        }
        if (!meta) {
            getHomeData();
        }
        // Note: Disabled below is to cause this useEffect to run only after the initial render.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (library.uri && library.loaded) {
            firePageViewEvent();
        }

        // Note: Disabled below... this useEffect will only run when the library.uri or library.loaded change.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [library.uri, library.loaded]);

    const firePageViewEvent = () => {
        const currentBreadcrumbs = library.breadCrumbs.map(({ uri }) =>
            uri.split("/").pop()
        );

        if (currentBreadcrumbs.length && !crossLinkMode && library.loaded) {
            analytics.firePageViewEvent(asyncCheckLogin, {
                page: {
                    info: {
                        breadcrumbs: library.breadCrumbs.map(({ uri }) =>
                            uri.split("/").pop()
                        ),
                        language: lang,
                    },
                    category: {
                        ...buildCategoryTypes(library.uri),
                        type: "navigation",
                    },
                },
            });
        }
    };

    const getSecureData = async () => {
        const annotationAvailable = !annotationsOutOfService && !crossLinkMode;

        if ((loggedIn || (await asyncCheckLogin())) && annotationAvailable) {
            getBookmarks();
            getSets();
        }
    };

    return <Home {...props} />;
};

export default withRouter(storeConnector(HomeLibraryContainer));
