import { get } from "@churchofjesuschrist/universal-env";
import {
    toReducerKey,
    deconstructReducerKey,
} from "../../util/reducer-helpers";
import { createSelector } from "../../util/reselect";
import {
    prependAppBase,
    removeAppBase,
    removeVerse,
    searchToParams,
    urlToDotNotation,
} from "../../util/uri-utils";
import { isPromise } from "../../util/utils";

const { APP_PATHNAME, ALLOW_STRING_FALLBACK } = get();

// system
export const selectAnnotationsOutOfService = (state) =>
    state.system.annotationsOutOfService;

export const selectHTTPResponseCode = (state) => state.system.status;

export const selectLang = (state, locationOrId) =>
    locationOrId
        ? typeof locationOrId === "string"
            ? deconstructReducerKey(locationOrId).lang
            : searchToParams(locationOrId.search).lang
        : state.system.lang;

// system.loggedIn can be false, true, a promise, or null (unknown)
export const _selectLoggedIn = (state) => state.system.loggedIn;

// force system.loggedIn to resolve to either true or false
export const selectLoggedIn = (state) => {
    let loggedIn = _selectLoggedIn(state);

    return !isPromise(loggedIn) && !!loggedIn;
};

export const selectCSSRules = (state) => state.system.cssRules;

export const selectCSSLinks = createSelector(
    [
        selectCSSRules,
        (state, locationOrId) =>
            `/${selectTocKey(state, locationOrId)
                .split("/")
                .slice(2)
                .join("/")}`,
    ],
    (cssRules, tocUri) =>
        cssRules
            .filter(({ matches }) =>
                matches.some((match) => tocUri.startsWith(match))
            )
            .pop()
            .styles.map((href) => ({
                type: "array",
                rel: "stylesheet",
                "orig-type": "text/css",
                href,
            }))
);

// router
export const selectHistoryAction = (state) => state.router.action;

export const selectIsFirstRendering = (state) => state.router.isFirstRendering;

// coreContent
export const selectCoreContentFragments = (state) => state.coreContent;

// home
export const selectHome = (state) => state.home.collection;

// i18n
export const selectI18nStringById = createSelector(
    [
        (state) => state.i18n[selectLang(state)] || {},
        (state) => state.i18n.eng || {},
        (state) => state.preview,
    ],
    (resources, fallbackStrings, preview) => (id) =>
        resources[id]?.text ||
        fallbackStrings[id]?.text ||
        (preview || parseInt(ALLOW_STRING_FALLBACK) ? id : "")
);

export const selectI18nHrefById = createSelector(
    [
        (state) => state.i18n[selectLang(state)] || {},
        (state) => state.i18n.eng || {},
        (state) => state.preview,
    ],
    (resources, fallbackStrings, preview) => (id) =>
        resources[id]?.href ||
        fallbackStrings[id]?.href ||
        (preview || parseInt(ALLOW_STRING_FALLBACK) ? id : "")
);

// error
const errorKeyLookup = {
    401: {
        header: "noAuthTop",
        title: "noAuthTitle",
        noAuthLine1: "noAuthLine1",
        noAuthLine2: "noAuthLine2",
    },
    404: {
        header: "errorCodeString",
        title: "notFoundTitle",
        showStatus: true,
    },
    500: {
        header: "errorCodeString",
        title: "generalErrorMessage",
        errorInstruction: "generalErrorInstruction",
        showStatus: true,
    },
    other: {
        header: "errorCodeString",
        title: "otherTitle",
        errorInstruction: "otherDesc",
        showStatus: true,
    },
};

export const selectErrorByStatus = createSelector(
    [selectI18nStringById],
    (selectI18nStringById) => (status) => {
        const { showStatus, ...strings } =
            errorKeyLookup[status] || errorKeyLookup["other"];

        const retrievedStrings = Object.entries(strings).reduce(
            (acc, [key, value]) => {
                acc[key] = selectI18nStringById(value);
                return acc;
            },
            {}
        );

        return {
            errorButtonText: selectI18nStringById("errorButtonText"),
            showStatus: showStatus,
            ...retrievedStrings,
        };
    }
);

// library
const NO_LIBRARY = { isDefault: true };

export const buildDynamicContentKey = (location) =>
    toReducerKey(
        removeVerse(location.pathname),
        searchToParams(location.search).lang
    );

export const resolveDynamicContentKey = (locationOrId) =>
    typeof locationOrId === "string"
        ? locationOrId
        : buildDynamicContentKey(locationOrId);

export const selectPlainLibrary = (state, locationOrId) =>
    state.library[resolveDynamicContentKey(locationOrId)] || NO_LIBRARY;

// entries have two structures that diverge after the first 'entries' key:
// with subsections - entries[i].section.entries[i].(item||collection)
// no subsections - entries[0][i].(item||collection)
export const selectLibrary = createSelector(
    [selectPlainLibrary, selectI18nStringById, selectLang],
    (plainLibrary, selectI18nStringById, lang) => {
        const { breadCrumbs = [], sections = [], ...library } = plainLibrary;

        const canonicalUrl = library.uri
            ? `${removeAppBase(library.uri)}?lang=${lang}`
            : `?lang=${lang}`;

        return {
            breadCrumbs,
            canonicalUrl,
            title: selectI18nStringById("defaultLibraryBreadCrumb"),
            uri: `${APP_PATHNAME}`,
            ...library,
            sections,
        };
    }
);

// reader
const NO_ASSOCIATED_MEDIA = {};
const NO_AUDIO = [];
const NO_CONTENT = {};
const NO_FOOTNOTES = {};
const NO_NAV = [];
const NO_TOC = {};

export const _selectContent = (state, locationOrId) => {
    let key = resolveDynamicContentKey(locationOrId);
    let content = state.reader.contentStore[key] || NO_CONTENT;

    return content;
};

export const selectContent = (state, locationOrId) => {
    let content = _selectContent(state, locationOrId);

    return content.redirect ? selectContent(state, content.redirect) : content;
};

export const selectContentTemplateType = (state, locationOrId) => {
    const content = selectContent(state, locationOrId);

    if (!content.content) {
        return undefined;
    }

    const cssName = content.content.head.links[0]?.href || "";
    const cssNameSplit = cssName.split("/");

    let type = undefined;

    if (cssName.includes("global-template")) {
        type = cssNameSplit[cssNameSplit.length - 1].split("_")[2];
    } else if (
        /_contents|_manifest/.test(content.meta.pageAttributes["data-uri"])
    ) {
        type = "contents/manifest";
    }

    return type;
};

export const selectScopedClassName = (state, locationOrId) => {
    const content = selectContent(state, locationOrId);

    if (!content.content) {
        return undefined;
    }

    return content.meta.scopedClassName || "";
};

export const selectContentType = (state, locationOrId) => {
    const book = selectPlainToc(state, locationOrId);

    return book?.contentType || undefined;
};

const normalizeAudio = (audio) => [].concat(audio);

export const selectAudioSources = createSelector(
    [
        (state, locationOrId) =>
            selectContent(state, locationOrId)?.meta?.audio || NO_AUDIO,
    ],
    (audio) => {
        audio = normalizeAudio(audio);

        return audio.length
            ? audio.map((item) => ({
                  name: item.variant,
                  source: item.mediaUrl,
                  fileSize: item.fileSize,
                  duration: item.duration,
              }))
            : undefined;
    }
);

const _selectPlainFootnotes = (state, locationOrId) =>
    selectContent(state, locationOrId)?.content?.footnotes || NO_FOOTNOTES;

export const selectFootnotes = createSelector(
    [_selectPlainFootnotes, selectCoreContentFragments, selectLang],
    (footnotes, coreContentFragments, lang) => {
        const buildReference = ({ href, text }) => {
            const dotNotationHref = urlToDotNotation(href);
            let {
                referenceURI,
                referenceURIDisplayText = text,
                ...reference
            } = coreContentFragments[
                toReducerKey(dotNotationHref.split(/[?#]/)[0], lang)
            ] || {};

            referenceURI = prependAppBase(referenceURI || href);

            return {
                referenceURI,
                referenceURIDisplayText,
                ...reference,
            };
        };

        return Object.entries(footnotes).reduce(
            (footnotes, [key, value]) => ({
                ...footnotes,
                [key]: {
                    ...value,
                    references: (value.referenceUris || []).map(buildReference),
                },
            }),
            {}
        );
    }
);

export const selectAssociatedMedia = (state, locationOrId) =>
    selectContent(state, locationOrId)?.content?.associated ||
    NO_ASSOCIATED_MEDIA;

export const selectPids = (state, locationOrId) =>
    selectContent(state, locationOrId).pids;

export const selectContentTitlePid = (state, locationOrId) =>
    selectPids(state, locationOrId)?.[1]?.[0] || null;

const selectTocKey = createSelector(
    [
        selectContent,
        (state, locationOrId) => resolveDynamicContentKey(locationOrId),
    ],
    (content, contentKey) => {
        let tableOfContentsUri = content.tableOfContentsUri;
        let lang = content?.meta?.pageAttributes?.lang;

        return tableOfContentsUri
            ? toReducerKey(tableOfContentsUri, lang)
            : contentKey;
    }
);

export const selectPlainToc = createSelector(
    [selectTocKey, (state) => state.reader.bookStore],
    (key, bookStore) => {
        let toc = bookStore[key];

        if (!toc) {
            key = Object.keys(bookStore)
                .sort((a, b) => a.length - b.length)
                .find((str) => key.includes(`${str}/`) || str === key);

            toc = bookStore[key];
        }

        return toc || NO_TOC;
    }
);

const buildNavList = (navList, entry) => {
    let uris = entry.content
        ? [entry.content.uri]
        : entry.section.type === "content"
        ? [entry.section.uri].concat(entry.section.childUris)
        : entry.section.childUris;

    return [...navList, ...uris];
};

export const selectToc = createSelector([selectPlainToc], (book) => ({
    ...book,
    navList: (book.entries || []).reduce(buildNavList, NO_NAV),
}));

export const selectTocTitle = createSelector(
    [selectPlainToc],
    (book) => book.title
);

export const selectDownloads = createSelector(
    [
        selectPlainToc,
        (state, locationOrId) => selectContent(state, locationOrId)?.meta?.pdf,
        selectAudioSources,
    ],
    ({ pdfDownloads = [], fullBookAudio }, pagePdf, audio = []) => {
        let downloads = [
            fullBookAudio,
            ...audio,
            ...pdfDownloads,
            pagePdf,
        ].filter((item) => item && item.name && item.source);

        return downloads.length ? downloads : undefined;
    }
);

// dynamic
export const selectDynamicStatusCode = createSelector(
    [selectLibrary, selectContent, selectToc],
    (library, content, book) => {
        if (library.status && book.status && content.status) {
            // all statuses should be the same so return library
            return library.status;
        } else {
            return 200;
        }
    }
);

export const selectNoIndexStatus = (state, locationOrId) => {
    const library = selectLibrary(state, locationOrId);
    const content = selectContent(state, locationOrId);
    const book = selectToc(state, locationOrId);

    return library.noIndex || book.noIndex || content.noIndex;
};

// styles
const _selectStyleSheets = (state) => state.styles.styleSheets;

export const selectStyleSheets = createSelector(
    [_selectStyleSheets],
    (styleSheets) => Object.values(styleSheets)
);

export const selectLoadedStyles = (state) => state.styles.loadedStyles;

// local settings
export const selectDefaultStyle = (state) => state.localSettings.defaultStyle;

export const selectFontSansSerif = (state) => state.localSettings.fontSansSerif;

export const selectFontSize = (state) => state.localSettings.fontSize;

export const selectDisplayFootnotes = (state) =>
    state.localSettings.displayFootnotes;

export const selectPrintFootnotes = (state) =>
    state.localSettings.printFootnotes;

export const selectPrintHighlights = (state) =>
    state.localSettings.printHighlights;

export const selectImagePreferences = (state) =>
    state.localSettings.disableImages;

export const selectShowArchivedContent = (state) =>
    state.localSettings.showArchivedContent;

export const selectToolbarClosed = (state) => state.localSettings.toolbarClosed;

export const selectTheme = (state) => state.localSettings.theme;

// notifications
export const selectAllNotifications = (state) =>
    state.notifications.notifications;

export const selectToast = (state) => state.notifications.toast;

export const selectSeenNotifications = (state) =>
    state.notifications.seenNotifications;

export const selectNotificationById = (state, notificationId) => {
    const allNotifications = selectAllNotifications(state);

    return allNotifications?.find(({ id }) => id === notificationId);
};

export const selectShowLowServiceBanner = (state) =>
    state.notifications.showLowServiceBanner;

export const selectVisibleNotifications = (state) =>
    state.notifications.visibleNotifications;

export const selectUnseenNotificationsByLocation = (state, location) => {
    const isReaderPage = selectContent(state, location);
    const allNotifications = selectAllNotifications(state);
    const seenNotifications = selectSeenNotifications(state) || [];

    // Intentionally set as undefined when the notifications have not been loaded
    if (!Array.isArray(allNotifications)) {
        return undefined;
    }

    const allUnseenNotifications = allNotifications.filter(
        ({ id, locatorIds = [] }) => {
            const notYetSeen = !seenNotifications.includes(id);
            const isNotTriggeredNotification = !locatorIds.some((locatorId) =>
                locatorId.startsWith("trigger-")
            );

            return notYetSeen && isNotTriggeredNotification;
        }
    );

    const nonRefNotifications = allUnseenNotifications.filter(
        (note) => !note.referenceList
    );
    const refNotifications = allUnseenNotifications.filter(
        (note) => note.referenceList
    );

    const currentRefNotifications = refNotifications.filter(
        ({ referenceList }) => {
            const refList = referenceList.filter((ref) =>
                ref.includes(`${location.pathname}?`)
            );

            return refList.length;
        }
    );

    const unseenNotifications = isReaderPage
        ? currentRefNotifications.concat(nonRefNotifications)
        : currentRefNotifications;

    return unseenNotifications;
};
