/*  Utility functions for Adobe Launch  */

import { isAppBase, removeAppBase } from "./uri-utils";

const defaultDigitalDataEvents = {
    pageView: "Page View",
    productView: "Product View",
    addToCart: "Add to Cart",
    removeFromCart: "Remove from Cart",
    checkout: "Checkout",
    purchase: "Purchase",
    search: "Search",
    component: {
        click: "Component Click",
        share: "Share",
        formStart: "Form Start",
        formError: "Form Error",
        formSubmit: "Form Submit",
    },
};

const ensureDataLayer = () => {
    window.digitalData = window.digitalData || [];
    window.digitalDataEvents = {
        ...defaultDigitalDataEvents,
        ...window.digitalDataEvents,
        component: {
            ...defaultDigitalDataEvents.component,
            ...window.digitalDataEvents?.component,
        },
    };

    return new Promise((resolve) => {
        document.readyState === "complete"
            ? resolve()
            : window.addEventListener("load", resolve);
    });
};

const grabDisplaySettings = () => {
    const fontSize = JSON.parse(localStorage.getItem("fontSize")) ?? 1;
    const fontSansSerif =
        JSON.parse(localStorage.getItem("fontSansSerif")) ?? false;
    const theme =
        localStorage.getItem("theme") === "os"
            ? window.matchMedia &&
              window.matchMedia("(prefers-color-scheme: dark)").matches
                ? "os:dark"
                : "os:light"
            : localStorage.getItem("theme");

    return {
        fontSize: `${fontSize}`,
        fontStyle: fontSansSerif ? "san serif" : "serif",
        theme,
    };
};

const firePageViewEvent = async (
    asyncCheckLogin,
    { page = {}, user = {}, ...data }
) => {
    await ensureDataLayer();
    const displaySettings = grabDisplaySettings();

    window.digitalData.push({
        event: window.digitalDataEvents.pageView,
        page: {
            ...page,
            attributes: {
                ...displaySettings,
                ...page?.attributes,
            },
            info: {
                name: isAppBase(window.location.pathname)
                    ? "study"
                    : removeAppBase(window.location.pathname)
                          .slice(1)
                          .replace(/\//g, "|"),
                siteName: "Gospel Library Online",
                ...page?.info,
            },
            category: {
                primary: "Gospel Library",
                ...page?.category,
            },
        },
        user: {
            ...user,
            profile: {
                ...user?.profile,
                info: {
                    loginStatus: (await asyncCheckLogin())
                        ? "signed in"
                        : "signed out",
                    ...user?.profile?.info,
                },
            },
        },
        ...data,
    });
};

const fireShareEvent = ({ component = {}, ...data }) => {
    ensureDataLayer();

    window.digitalData.push({
        event: window.digitalDataEvents.component.share,
        ...data,
        component: {
            ...component,
            category: {
                primary: "social media",
                ...component?.category,
            },
        },
    });
};

const fireStudyToolsEvent = ({ component = {}, ...data }) => {
    ensureDataLayer();

    window.digitalData.push({
        event: window.digitalDataEvents.component.click,
        ...data,
        component: {
            ...component,
            category: {
                primary: "study tools",
                ...component?.category,
            },
        },
    });
};

const fireAssociatedRelatedContentEvent = ({ context, type }) => {
    ensureDataLayer();

    const infoString = type ? `-${type}` : "";
    const typeString = type ? " click" : "";

    window.digitalData.push({
        event: window.digitalDataEvents.component.click,
        component: {
            info: {
                name: `${context} content${infoString}`,
            },
            category: {
                primary: "study tools",
                type: `associated-related content${typeString}`,
            },
        },
    });
};

const fireRelatedContentEvent = ({ location, type }) => {
    ensureDataLayer();

    window.digitalData.push({
        event: window.digitalDataEvents.component.click,
        component: {
            info: {
                location,
            },
            category: {
                primary: "related content",
                type,
            },
        },
    });
};

const fireNavigationEvent = ({ location, menu, menuItem, href }) => {
    ensureDataLayer();

    window.digitalData.push({
        event: window.digitalDataEvents.component.siteNavigation,
        component: {
            info: {
                location,
                menu,
                menuItem,
                href,
            },
            category: {
                primary: "navigation",
            },
        },
    });
};

const fireSettingsEvent = ({ component = {}, ...data }) => {
    ensureDataLayer();

    window.digitalData.push({
        event: window.digitalDataEvents.component.click,
        ...data,
        component: {
            ...component,
            category: {
                primary: "settings",
                ...component?.category,
            },
        },
    });
};

const fireComponentClickEvent = ({ component = {}, ...data }) => {
    ensureDataLayer();

    window.digitalData.push({
        event: window.digitalDataEvents.component.click,
        component: { ...component },
        ...data,
    });
};

export default {
    firePageViewEvent,
    fireShareEvent,
    fireSettingsEvent,
    fireStudyToolsEvent,
    fireComponentClickEvent,
    fireAssociatedRelatedContentEvent,
    fireRelatedContentEvent,
    fireNavigationEvent,
};
