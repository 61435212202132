import {
    GET_TOC_DATA,
    GET_READER_CONTENT,
    CLEAR_TOC_COLLECTION_OVERRIDES,
    SET_TOC_COLLECTION_OVERRIDE,
} from "../actions/reader";
import { reduceEntries } from "../../util/reducer-helpers";

export default (
    state = { contentStore: {}, bookStore: {} },
    { type, payload = {} }
) => {
    switch (type) {
        case GET_TOC_DATA:
            return {
                ...state,
                bookStore: {
                    ...state.bookStore,
                    ...payload,
                },
            };

        case GET_READER_CONTENT:
            return {
                ...state,
                contentStore: {
                    ...state.contentStore,
                    ...payload,
                },
            };

        case CLEAR_TOC_COLLECTION_OVERRIDES:
            return {
                ...state,
                bookStore: Object.entries(state.bookStore)
                    .map(
                        ([
                            key,

                            {
                                // eslint-disable-next-line no-unused-vars -- define so it isn't included in ...value
                                parentNameOverride,
                                // eslint-disable-next-line no-unused-vars -- define so it isn't included in ...value
                                parentUriOverride,
                                ...value
                            },
                        ]) => [key, value]
                    )
                    .reduce(reduceEntries, {}),
            };

        case SET_TOC_COLLECTION_OVERRIDE:
            return {
                ...state,
                bookStore: {
                    ...state.bookStore,
                    [payload.bookKey]: {
                        ...state.bookStore[payload.bookKey],
                        parentNameOverride: payload.parentNameOverride,
                        parentUriOverride: payload.parentUriOverride,
                    },
                },
            };
        default:
            return state;
    }
};
