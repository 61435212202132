import { GET_LIBRARY_DATA } from "../actions/homeLibrary";
import { toReducerKey } from "../../util/reducer-helpers";

export default (state = {}, { type, payload = {}, lang = "eng" }) => {
    let key;

    switch (type) {
        case GET_LIBRARY_DATA:
            key = toReducerKey(payload.uri || "", lang);

            return {
                ...state,
                [key]: {
                    ...state[key],
                    ...payload,
                    loaded: true,
                },
            };

        default:
            return state;
    }
};
