import { isAppBase, removeAppBase } from "./uri-utils";

const resolveTypes = (key) => {
    switch (key) {
        case "scriptures":
            return {
                type: "scriptures",
                placeholder: "searchContextScriptures",
                subTypes: [
                    { type: "ot", placeholder: "searchContextOldTestament" },
                    { type: "nt", placeholder: "searchContextNewTestament" },
                    { type: "bofm", placeholder: "searchContextBookOfMormon" },
                    { type: "dc-testament", placeholder: "searchContextDnC" },
                    { type: "pgp", placeholder: "searchContextPGP" },
                ],
            };

        case "general-conference":
            return {
                type: "general-conference",
                placeholder: "searchContextGeneralConference",
            };

        case "come-follow-me":
            return {
                type: "come-follow-me",
                placeholder: "searchContextComeFollowMe",
            };

        case "general-handbook":
            return {
                type: "general-handbook",
                placeholder: "searchContextHandbook",
            };

        case "books-and-lessons":
        case "manual":
        case "manuals":
            return {
                type: "manuals",
                placeholder: "searchContextBooksAndLessons",
            };

        case "liahona":
            return { type: "liahona", placeholder: "searchContextLiahona" };

        case "for-the-strength-of-youth":
            return {
                type: "for-the-strength-of-youth",
                placeholder: "searchContextFTSOY",
            };

        case "friend":
            return { type: "the-friend", placeholder: "searchContextFriend" };

        case "ya-weekly":
            return {
                type: "young-adult-weekly",
                placeholder: "searchContextYAWeekly",
            };

        case "church-history":
            return {
                type: "church-history",
                placeholder: "searchContextChurchHistory",
            };

        case "gospel-topics":
        case "topics":
            return {
                type: "gospel-topics",
                placeholder: "searchContextGospelTopics",
            };

        default:
            return undefined;
    }
};

export const updateSearchFacet = (libraryUri, bookUri, I18nStringById) => {
    const [libraryRoot = "/", library] = removeAppBase(libraryUri)
        .slice(1)
        .split("/");
    const [bookRoot, book] = removeAppBase(bookUri).slice(1).split("/");

    let {
        type = "all",
        subTypes = [],
        placeholder = "searchContextGospelLibrary",
    } = resolveTypes(book) ||
    resolveTypes(bookRoot) ||
    resolveTypes(library) ||
    resolveTypes(libraryRoot) ||
    {};
    let subType = subTypes.find((item) => item.type === book);

    window.platformConfig.searchConfig.searchOptionalParams = [
        { param: "facet", value: type },
        subType && { param: "subfacet", value: subType.type },
    ].filter(Boolean);

    const placeholderString = `${I18nStringById(
        "searchContextSearch"
    )} ${I18nStringById(subType ? subType.placeholder : placeholder)}`;
    window.platformConfig.searchConfig.searchPlaceholderText =
        placeholderString;

    document.dispatchEvent(new CustomEvent("checkcontext"));
};

export const buildCategoryTypes = (uri = "/") => {
    const adjustedUri = isAppBase(uri) ? uri + "/" : uri;
    const [root = "/"] = removeAppBase(adjustedUri).slice(1).split("/");

    // At the request of analytics, if the uri is "/study", primary category should be "home".
    // Otherwise the primary will be "" (a blank string)
    let { type: primary = root === "" ? "home" : root } =
        resolveTypes(root) || {};

    return { primary };
};
