import { get } from "@churchofjesuschrist/universal-env";
import { Component } from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import HomeLibrary from "./home-library.js";
import Dynamic from "./dynamic.js";
import NotFound from "./not-found.js";
import AppView from "../templates/AppView";
import StyleManager from "../components/StyleManager";
import { getFromSessionStorage } from "../../util/store-on-client";
import {
    selectLang,
    selectLibrary,
    selectContent,
    selectToc,
    selectI18nStringById,
    selectToast,
} from "../selectors";
import { searchToParams } from "../../util/uri-utils";
import { getI18nData } from "../actions/i18n";
import {
    saveBackedUpAnnotation,
    cleanUpUnsavedAnnotations,
} from "../actions/handleSaveError";
import {
    getNotifications,
    setVisibleNotifications,
} from "../actions/notifications";
import { asyncCheckLogin, fireLocationChange } from "../actions/system";
import { Route, Switch, withRouter } from "react-router-dom";
import LocalSettings from "../components/LocalSettings";
import { GeneralContextProvider } from "../components/GeneralContext";
import { langToLangCode } from "@churchofjesuschrist/lang/lite";

const { APP_PATHNAME } = get();

const mapStateToProps = (state, ownProps) => {
    let location = ownProps.location;
    let { lang } = searchToParams(location.search);
    let book = selectToc(state, location);
    let content = selectContent(state, location);
    let library = selectLibrary(state, location);
    let toast = selectToast(state);

    book = book.entries ? book : {};
    content = content.content ? content : {};
    library = library.loaded ? library : {};

    return {
        toast,
        bookTitle: book.title || "",
        bookUri: book.uri,
        breadCrumbs: library.breadCrumbs || [],
        contentTitle: content?.meta?.title || "",
        dir: state.system.dir,
        i18n: state.i18n,
        lang,
        location,
        libraryUri: library.uri || book.parenturiOverride || book.parentUri,
        system: state.system,
        selectI18nStringById: selectI18nStringById(state),
    };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
    ...ownProps,
    ...stateProps,
    ...dispatchProps,
});

const storeConnector = connect(
    mapStateToProps,
    {
        asyncCheckLogin,
        fireLocationChange,
        getI18nData,
        saveBackedUpAnnotation,
    },
    mergeProps
);

class AppContainer extends Component {
    static async updateInitialStore(store) {
        let state = store.getState(),
            context = state.preview ? "preview" : undefined,
            dispatch = store.dispatch,
            headers = { cookie: state.headers.cookie },
            lang = selectLang(state);

        let actions = [
            getI18nData({ lang, context }, headers),
            getNotifications({ lang, context }, headers),
            setVisibleNotifications([]),
        ];

        // load english strings for a fallback
        lang !== "eng" &&
            actions.push(getI18nData({ lang: "eng", context }, headers));

        await Promise.all(actions.map(dispatch));
    }

    async componentDidMount() {
        const { asyncCheckLogin, getI18nData, i18n, lang } = this.props;

        if (window.navigator.platform.startsWith("Mac")) {
            document.body.classList.add("force-scrollbar");
        }

        // polyfill for elementsFromPoint
        if (!document.elementsFromPoint) {
            document.elementsFromPoint = (x, y) => {
                let parents = [];
                let parent;

                do {
                    if (parent !== document.elementFromPoint(x, y)) {
                        parent = document.elementFromPoint(x, y);
                        parents.push(parent);
                        parent.style.pointerEvents = "none";
                    } else {
                        parent = false;
                    }
                } while (parent);

                parents.forEach(
                    (parent) => (parent.style.pointerEvents = "all")
                );

                return parents;
            };
        }

        const loggedIn = await asyncCheckLogin();

        // check for local annotation
        if (loggedIn) {
            const unsavedAnnotations = JSON.parse(
                getFromSessionStorage("unsavedAnnotations")
            );

            if (unsavedAnnotations) {
                const promises = [];

                for (let key in unsavedAnnotations) {
                    promises.push(
                        this.props.saveBackedUpAnnotation(
                            unsavedAnnotations[key]
                        )
                    );
                }

                Promise.all(promises)
                    .then(() => cleanUpUnsavedAnnotations())
                    .catch((error) =>
                        console.error(
                            "Error - failed to save backed up annotation",
                            error
                        )
                    );
            }
        }

        !i18n[lang] && getI18nData({ lang });
    }

    render() {
        const { children, dir, lang, ...props } = this.props;
        let isoLang = lang;

        try {
            isoLang = langToLangCode(lang);
        } catch (err) {
            console.error(err);
        }

        return (
            <GeneralContextProvider lang={lang}>
                <AppView {...props}>
                    <LocalSettings />
                    <Helmet>
                        <html lang={isoLang} />
                        <body dir={dir} />
                    </Helmet>
                    <StyleManager />
                    <Switch>
                        <Route
                            exact
                            path={APP_PATHNAME}
                            component={HomeLibrary}
                        />
                        <Route
                            exact
                            path={`${APP_PATHNAME}/notFound`}
                            component={NotFound}
                        />
                        <Route path={`${APP_PATHNAME}/*`} component={Dynamic} />
                        <Route path="*" component={NotFound} />
                    </Switch>
                    {children}
                </AppView>
            </GeneralContextProvider>
        );
    }
}

export default storeConnector(withRouter(AppContainer));
